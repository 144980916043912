import React from 'react'
import { NavLink } from 'react-router-dom'

//Images
import { home_intro} from 'images/ImageComponents'

//Style
import "./splitIntro.css"

export default function SplitIntro({page}) {
    return (
        <div className='home__intro'>
            <div className='max-width'>
                <div className='home-intro__left'>
                    <h1>Split Group Therapy</h1>
                    <p>“One of the best words one likes to hear is “me too”, it just comforts you and makes you feel that whatever you are going through is normal and others go through it as well” </p>
                    <p>Sometimes when you feel down you tend to isolate, and feel that no one understands you, Loneliness and isolation tend to lead to more loneliness and isolation, but making the effort to connect with others is just the thing we may need to start feeling better.</p>
                    {page == "home" && <NavLink to={"aboutsplit"} ><button>More Information</button></NavLink>}
                </div>
                <div className='home-intro__right'>
                    <img src={home_intro} alt='Group Video Call'/>
                    
                    <button>Contact us</button>
                </div>
            </div>
        </div>
    )
}
