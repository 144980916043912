import React, { useEffect } from 'react'

//Custom Components
import SSworksCard from './Components/SSworksCard/SSworksCard'

//Images
import { home_therapy_types,
            home_how_it_works} from 'images/ImageComponents'

//Styles
import "./home.css"
import SplitIntro from 'Common/Sections/SplitIntro/SplitIntro'
import Emergency from 'Common/Sections/Emergency/Emergency'

//Constants
const typeOfTherapies =[
    {
        type: "Individual",
        description: "(For myself)"
    },
    {
        type: "Couple",
        description: "(For me and my partner)"
    },
    {
        type: "Family",
        description: "(For me and my Family)"
    },
    {
        type: "Teen",
        description: "(For my child)"
    },
    {
        type: "Split Group",
        description: "(For myself and others)"
    }
]
const howItWorksSteps =[
    {
        title: "Choose the type of therapy",
        description:"Get introduced to many types of therapy and choose the right one for you, we will be guiding you through the way."
    },
    {
        title: "Get matched to the right therapist",
        description:"Answer a few questions to get matched with the right therapist. You can also choose therapist you like.  "
    },
    {
        title: "Book your session",
        description:"Choose date and time that suits you, and pay using our secure online payment method."
    },
    {
        title: "Start your treatment",
        description:"You can communicate with your therapist through all types of technology or Face to Face."
    }
]

export default function Home() {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Home | Safe Space";
    }, []);
    
    return (
        <div className='home__container'>
            <SplitIntro page={"home"} />
            <div className='home__therapy-types' >
                <img src={home_therapy_types} alt='Flowers Blooming'/>
                <h1>Start blooming, through finding yourself.</h1>
                <h3>What type of therapy are you looking for?</h3>
                <div className='therapy-types__question'>
                    {typeOfTherapies.map(therapy => (
                        <button>
                            <span>{therapy.type}</span>
                            <span>{therapy.description}</span>
                        </button>
                    ))}
                </div>
            </div>
            <div className='home__how-it-works'>
                <div className='max-width'>
                    <div className='how-it-works__left'>
                        {howItWorksSteps.map((step, index) => (
                            <SSworksCard 
                                title={step.title}
                                description={step.description}
                                index={index}
                                next={howItWorksSteps[index + 1]}/>
                        ))}
                    </div>
                    <div className='how-it-works__right'>
                        <img src={home_how_it_works} alt='Loading.... '/>
                    </div>
                </div>
            </div>
            <Emergency />
        </div>
    )
}
