import React, {useEffect} from 'react'

//Images
import { aboutUs_intro,
            aboutUs_our_mission } from 'images/ImageComponents'

//Styles
import "./aboutUs.css"

export default function AboutUs() {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "About Us | Safe Space";
    }, []);

    return (
        <div className='about-us__container'>
            <div className='about-us__intro'>
                <div className='max-width'>
                    <div className='intro__left'>
                        <h1>About Us</h1>
                        <p>At Safe Space, we're dedicated to supporting mental wellness with a culturally sensitive focus. Our mission is to fill the gap in mental health services for the Arabic-speaking and Muslim community. We provide a safe environment for professional therapy, psychoeducation, and group sessions, all while respecting and preserving Islamic and Arabic values.
                        </p>
                    </div>
                    <div className='intro__right'>
                        <img src={aboutUs_intro} alt='About Us' />
                    </div>
                </div>
            </div>
            <div className='about-us__our-mission'>
                <div className='mission__left'>
                    <img src={aboutUs_our_mission} alt='About Us' />
                </div>
                <div className='mission__right'>
                    <h1>Our Mission</h1>
                    <p>At Safe Space, we're dedicated to supporting mental wellness with a culturally sensitive focus. Our mission is to fill the gap in mental health services for the Arabic-speaking and Muslim community. We provide a safe environment for professional therapy, psychoeducation, and group sessions, all while respecting and preserving Islamic and Arabic values.</p>
                </div>
            </div>
        </div>
    )
}
