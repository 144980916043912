import React, {useState} from "react";
import { NavLink, Outlet } from "react-router-dom";
import { nav_logo } from "images/ImageComponents";
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import {  useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

// Custom Components
import Footer from "./Components/Footer/Footer";    
// import DropdownMenu from "./Components/DropdownMenu/DropdownMenu";

// Style
import "./router.css";

const productsList = [
    {
        to:"FinancialSystems",
        name:"financial_systems"
    },
    {
        to:"DistributionSystems",
        name:"distribution_systems"
    },
    {
        to:"HumanResources",
        name:"human_resources"
    },
    {
        to:"FeaturedSystems",
        name:"featured_systems"
    },
]

export default function Router() { 
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = () => setIsOpen(!isOpen);
    const [productMenuIsOpen, setProductMenuIsOpen] = useState(false);
    const toggleProductMenu = () => setProductMenuIsOpen(!productMenuIsOpen);
    const currentLanguageCode = Cookies.get('i18next') || 'en';
    const { t , i18n} = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);



    const handleResize = () => {
        setWindowWidth(window.innerWidth);
        console.log(windowWidth)
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        document.body.dir = currentLanguageCode === 'ar' ? 'rtl': 'ltr';
    },[currentLanguageCode])

    return (
        <div>
            <header className='nav'>
                <nav className='nav_main-nav'>
                    <NavLink to="/" ><img src={nav_logo} alt='Safe Space'/></NavLink>
                    {windowWidth > 1028 ?
                    <div className='main-nav_navlinks' >
                        <NavLink className="navlink" to="/" >{t("nav.home")}</NavLink>
                        <NavLink className="navlink" to="aboutus" >{t("nav.aboutUs")}</NavLink>
                        <NavLink className="navlink" to="contactus" >{t("nav.contactUs")}</NavLink>
                        {/* <NavLink className="navlink" to="howitworks" >{t("nav.howItWorks")}</NavLink> */}
                        {i18n.language === "en" ? 
                        <button 
                            onClick={ () => {
                                i18n.changeLanguage('ar');
                                }
                            }
                            className='navlink button'
                        >
                            {t("nav.other_lang")} 
                            <FontAwesomeIcon icon={faGlobe} className='nav_language-icon'  />
                        </button>
                        :
                        <button 
                            onClick={ () => {
                                i18n.changeLanguage('en');
                                }
                            }
                            className='navlink'
                        >
                            <FontAwesomeIcon icon={faGlobe} className='nav_language-icon'  />
                            {t("nav.other_lang")} 
                        </button>}
                    </div>:
                    <div className="collapsed-navbar">
                        <button className="nav_door_button" onClick={toggleOpen} >
                            <div className={`nav-button ${isOpen ? 'open' : ''}`}></div>
                        </button>
                        <ul className={`${isOpen ? 'open' : ''} 
                            ${i18n.language === "ar" ? 'arabic_collapse-nav' : 'english_collapse-nav'}`}>
                            <li>
                                <NavLink className="navlink" to="/" >{t("nav.home")}</NavLink>
                            </li>
                            <li>
                                <NavLink className="navlink" to="aboutus" >{t("nav.aboutUs")}</NavLink>
                            </li>
                            <li>
                                <NavLink className="navlink" to="contactus" >{t("nav.contactUs")}</NavLink>
                            </li>
                            <li>
                                {i18n.language === "en" ? 
                                    <button 
                                        onClick={ () => {
                                            i18n.changeLanguage('ar');
                                            }
                                        }
                                        className='navlink lang_button'
                                    >
                                        {t("nav.other_lang")} 
                                        <FontAwesomeIcon icon={faGlobe} className='nav_language-icon'  />
                                    </button>
                                    :
                                    <button 
                                        onClick={ () => {
                                            i18n.changeLanguage('en');
                                            }
                                        }
                                        className='navlink lang_button'
                                    >
                                        {t("nav.other_lang")} 
                                        <FontAwesomeIcon icon={faGlobe} className='nav_language-icon'  />
                                    </button>}
                            </li>
                        </ul>
                    </div>}
                </nav>
            </header>
            <main>
                <Outlet></Outlet>
                <Footer />
            </main>
        </div>
    )
}
{/* <li>
        <button className="navlink collapsed-toggle-menu-button " onClick={toggleProductMenu} >
            Products
            <FontAwesomeIcon icon={faAngleDown} />
        </button>
    </li>
    <div className={`collapsed-toggle-menu-list ${productMenuIsOpen ? 'open' : ''}`}>
        {productsList.map(nav => (<li ><NavLink to={nav.to} className=" navlink ">{t("nav."+nav.name)}</NavLink></li>))}
    </div> */}