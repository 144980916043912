import React from 'react'

//Style
import "./ssWorksCard.css"
export default function SSworksCard({title, description, index, next}) {
    return (
        <div className='ssWorksCard__container'>
            <div className='order'>
                <div className='number'>{index+1}</div>
                {next && <div className='line'></div>}
            </div>
            <div className='card-info'>
                <h1>{title} </h1>
                <p>{description} </p>
            </div>
        </div>
    )
}
