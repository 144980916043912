import React from 'react'

//Style
import "./emergency.css"

export default function Emergency() {
    return (
        <div className='emergency_call'>
            <h1>If you are in a life threatening situation please contact any of these <span>emergency numbers</span> to get immediate help</h1>
        </div>
    )
}
