import React from 'react'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faPhoneFlip } from '@fortawesome/free-solid-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-router-dom'

import SocialIcons from 'Common/Components/SocialIcons/socialIcons'
import { useTranslation } from "react-i18next";

//Images
import { nav_logo } from 'images/ImageComponents'

// Style
import "./footer.css"


export default function Footer() {
    const { t, i18n } = useTranslation();

    return (
        <div className='footer'>
            <div className='footer__slogan' >
                <img src={nav_logo} alt='Safe Space' />
                <h1>Separate from reality, Integrate with yourself</h1>
            </div>
            <div className='footer_info'>
                <div className='info_colum'>
                    <h3>{t("footer.legal.title")}</h3>
                    <ul className='footer__link-list'>
                        <li>{t("footer.legal.list1")}</li>
                        <li>{t("footer.legal.list2")}</li>
                        <li>{t("footer.legal.list3")}</li>
                        <li>{t("footer.legal.list4")}</li>
                    </ul>
                </div>
                <div className='info_colum'>
                    <h3>{t("footer.help.title")}</h3>
                    <ul className='footer__link-list'>
                        <li>{t("footer.help.list1")}</li>
                        <li>{t("footer.help.list2")}</li>
                        <li>{t("footer.help.list3")}</li>
                    </ul>
                </div>
                <div className='info_colum'>
                    <h3>{t("footer.therapists.title")}</h3>
                    <div className='footer_new-therapists'>
                        <button>Join us as a therapist</button>
                    </div>
                </div>
                <div className='info_colum'>
                    <h3>{t("footer.social.title")}</h3>
                    <SocialIcons />
                </div>
            </div>
            <div className='footer_published'>
                <p>Copyright © 2022 Safe Space</p>
            </div>
        </div>
    )
}
