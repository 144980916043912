import React, {useEffect} from 'react'

//Custom Components
import Emergency from 'Common/Sections/Emergency/Emergency'

//Images
import { contactUs_intro } from 'images/ImageComponents'

//Style 
import './contactUs.css'
import ContactForm from './Components/ContactForm/ContactForm'

export default function ContactUs() {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Contact Us | Safe Space";
    }, []);

    return (
        <div className='contactUs_page'>
            <div className='contactUs_intro' >
                <div className='max-width'>
                    <div className='contactUs_intro_content'>
                        <h1>Get in Touch!</h1>
                        <p>
                            Have a question, suggestion, or just want to say hello? We'd love to hear from you! Fill out the form below, and our team will get back to you shortly.
                        </p>
                        <ContactForm />
                    </div>
                    <div className='contactUs_intro_image'>
                        <img src={contactUs_intro} alt='contact us ' />
                    </div>
                </div>
            </div>
            <Emergency />
        </div>
    )
}
