import React, { useState } from 'react';

//Style
import './contactForm.css'

function ContactForm() {
    // Setting up local state using the useState hook
    const [formValues, setFormValues] = useState({
        name: '',
        email: '',
        message: '',
    });

    // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
            // Process form submission here
            // For now, just log the current form values
            console.log(formValues);
        };

    // Handle changes in form fields
    const handleChange = (event) => {
        setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
        });
    };

    return (
        <form className="contactUs_form" onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="name"
                    placeholder='name'
                    className='contactUs_form_input'
                    value={formValues.name}
                    onChange={handleChange}
                />
            <br />
                <input
                    type="email"
                    name="email"
                    placeholder='email'
                    className='contactUs_form_input'
                    value={formValues.email}
                    onChange={handleChange}
                />
            <br />
                <textarea
                    name="message"
                    placeholder='message'
                    className='contactUs_form_input'
                    value={formValues.message}
                    onChange={handleChange}
                ></textarea>
            <br />
            <button type="submit">Submit</button>
        </form>
    );
}

export default ContactForm;
