import React from 'react'
/*CSS IMPORTS*/
import 'Common/Components/SocialIcons/socialIcons.css'
/*ICONS IMPORTS*/
import { faSquareFacebook, 
            faSquareTwitter,
            faSquareInstagram,
            faLinkedin} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SocialIcons = () => {
  const FACEBOOK_URL = "https://web.facebook.com/Asconarabia";
  const TWITTER_URL = "http://twitter.com/Asconarabia1";
  const INSTAGRAM_URL = "http://www.instagram.com/Asconarabia";
  const LINKEDIN_URL = "http://linkedin.com/company/Asconarabia";
  return (
    <div className='social__bar'>
      <a href={FACEBOOK_URL} target="_blank" rel="noreferrer" ><FontAwesomeIcon icon={faSquareFacebook} className='social__icon'/></a>
      <a href={TWITTER_URL} target="_blank" rel="noreferrer" ><FontAwesomeIcon icon={faSquareTwitter} className='social__icon'/></a>
      <a href={INSTAGRAM_URL} target="_blank" rel="noreferrer" ><FontAwesomeIcon icon={faSquareInstagram} className='social__icon'/></a>
      <a href={LINKEDIN_URL} target="_blank" rel="noreferrer" ><FontAwesomeIcon icon={faLinkedin} className='social__icon'/></a>
    </div>)
}

export default SocialIcons