import {
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { Route, RouterProvider } from "react-router-dom";

// Styles
import "./App.css";

// Custom Components
import Router from "./Common/Temp/Router/Router";

// Pages
import Home from "pages/Home/Home";
import AboutUs from "pages/AboutUs/AboutUs";
import HowItWorks from "pages/HowItWorks/HowItWorks";
import AboutSplit from "pages/AboutSplit/AboutSplit";
import ContactUs from "pages/ContactUs/ContactUs";


const router = createBrowserRouter(
  createRoutesFromElements(
      <Route path="/" element={<Router />}>
          <Route index element={<Home />} />
          <Route path="aboutus" element={<AboutUs />} />
          <Route path="howitworks" element={<HowItWorks />} />
          <Route path="aboutsplit" element={<AboutSplit />} />
          <Route path="contactus" element={<ContactUs /> } />
      </Route>
  )
);

function App() {
  return (
      <RouterProvider router={router} />
  );
}

export default App;
