import React, {useEffect} from 'react'
import SplitIntro from 'Common/Sections/SplitIntro/SplitIntro'

//Style
import "./aboutSplit.css"

export default function AboutSplit() {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Split | Safe Space";
    }, []);

    return (
        <div className='about-split__container'>
            <SplitIntro />
            <div className='about-split__content' >
                <p>“ one of the best words one likes to hear is “me too”, it just comforts you and makes you feel that whatever you are going through is normal and others go through it as well” Sometimes when you feel down you tend to isolate, and feel that no one understands you, Loneliness and isolation tend to lead to more loneliness and isolation, but making the effort to connect with others is just the thing we may need to start feeling better.</p>
                <p>As uncomfortable as it may sound, sometimes sharing difficult thoughts and feelings in a group setting can be extremely helpful in facilitating healing.</p>
                <p>This is the idea of group therapy, it is a form of therapy where a group of people gather together, to form a safe space and community, along with their therapist, to target a specific problem, such as depression, anxiety or improving social skills, helping people deal with a range of issues such as anger, shyness, loneliness and low self-esteem.</p>
                <h2>What is split?</h2>
                <p>Split is a form of group therapy that believes that a person is made of:</p>
                <ul>
                    <li>Mind</li>
                    <li>Body</li>
                    <li>Soul</li>
                </ul>
                <p>So we focus on all three together to improve the wellbeing. </p>
                <h2>How does it work?</h2>
                <p>A group of 7-12 people, along with their therapist, get together via zoom once a week for 7 weeks. The duration of the session is two hours: </p>
                <ul>
                    <li>(For mind) 50 min CBT group therapy: where we talk, share, learn techniques to help us cope and learn about our self, specifically, what leads to specific behaviors and what consequences result from those behaviors</li>
                    <li>10 min break</li>
                    <li>(For soul) 20 min Mindfulness session: through group exercise we learn how to tone out distractions and raise awareness to help reduce stress, reduce chronic pain, and improve sleep. It also improves our ability to recall information. Helps regulate emotions.</li>
                    <li>10 min break</li>
                    <li>(For body) 30 min Zumba class: We play together Zumba, first of all its fun, also it helps relieve stress, tones the body so helps with self-image, will help us as a group connect more :D and finally, Every time you exercise, you release endorphins, which trigger positive feelings throughout the body. </li>
                </ul>
                <h2>Is it for me?</h2>
                <p>If you suffer from minor depression and\or anxiety, or it will help you if you are acing normal life struggles and need to learn techniques to cope with it, or just simply if you need a safe space to talk to. </p>
                <p>This is why we make an assessment session so we can assure you are in the best group that is able to be your safe zone and push you to be better. </p>
            </div>
        </div>
    )
}
